<template>
  <div
    class="
      page-container
      w-screen
      h-vh-9/10
      relative
      flex flex-wrap
      justify-center
    "
    style="display: flex; justify-content: center; align-items: center;"
  >
    <div
      class="container flex flex-1 justify-center flex-wrap"
    >
      <div class="w-full my-5 text-2xl text-center text-primary uppercase">
        Terminos y Condiciones
      </div>
    </div>

    <div

        ref="scroll"
        style="text-align: justify; padding: 18%; padding-top: 0%; height: 100%; top:0;"
      >

        <h1>Sabar es una tienda en línea de seguros individuales, predefinidos por aseguradora por producto, que ofrece a sus usuarios una plataforma con una selección de productos y su diferentes variantes que se ajustan a múltiples necesidades con el objetivo de obtener una póliza en línea.</h1>
        <br>
        
        <h3 class="text-primary">1. Alcance del servicio</h3>
        <h1>1.1. Estos términos y condiciones están sujetos a cambios ocasionales y aplican a todos nuestros servicios proporcionados directa o indirectamente (p.ej. a través de terceros) a través de Internet, dispositivos móviles, correo electrónico o teléfono. </h1>
        <h1>1.2. Al hacer uso de nuestro sitio web, usted confirma que ha leído, entendido y aceptado estos términos y condiciones, al igual que la política de privacidad, incluyendo el uso de cookies.</h1>
        <br>

        <h3 class="text-primary">2. Servicios y contratos</h3>
        <h1>2.1. En el sitio web de sabar usted tiene la capacidad de cotizar los seguros por producto y por aseguradora y comprarlos utilizando la plataforma de sabar, pero con los medios que las mismas aseguradoras disponen para la recopilación de información, pagos y generación de pólizas en línea. </h1>
        <h1>2.2. Sabar no es el proveedor de los seguros cotizados. El servicio de sabar consiste en poder cotizar, pagar y obtener la póliza de seguro elegida. En particular, ningúna póliza de seguro se expide a través de la página principal de Sabar, sino que se hace mediante conexiones en tiempo real con las Aseguradoras involucradas, de las cuales sabar solo se queda con la información básica de la  cotización  y/o compra, para uso y consumo exclusivo del miembro de la plataforma que acceso para comprar su póliza. </h1>
        <h1>2.3. Este acuerdo no afecta a ningún otro acuerdo entre las aseguradoras y los usuarios.</h1>
        <br>

        <h3 class="text-primary">3. Zona para miembros de Sabar</h3>
        <h1>3.2. Al usar la zona para miembros de sabar, los usuarios podrán gestionar las pólizas compradas y acceder a los datos de la póliza emitida para en caso de un siniestro. Para crear una cuenta de usuario, será necesario aportar datos personales. Aparte del nombre de usuario, ningún otro dato personal será visible. Para obtener más información al respecto, recomendamos consultar el aviso de privacidad que opera “sabar”. Borrar la cuenta de usuario supondrá la eliminación de todos los datos del usuario de forma permanente. </h1>
        <h1>3.3. Un mismo usuario no se podrá registrar más de una vez. Además, corresponderá a este asegurarse de que sus datos personales sean correctos y completos. </h1>
        <h1>3.4. Los usuarios serán responsables de velar por el uso y la privacidad de los datos de su cuenta, en especial de su contraseña. Mas no será ser responsables del uso indebido que se haga de su cuenta.</h1>
        <h1>3.5. En el caso de que se haga un uso no autorizado de los servicios de sabar mediante nombre de usuario y una contraseña inapropiados, el usuario registrado deberá notificarlo a sabar inmediatamente.</h1>
        <br>

        <h3 class="text-primary">4. Privacidad, publicidad por correo electrónico</h3>
        <h1>4.1. La protección de los datos personales proporcionada por los usuarios es de suma importancia para sabar. Como tal, sabar realiza el mayor esfuerzo para asegurar el cumplimiento de la protección de datos. Para mayor información por favor lea nuestra política y aviso  de privacidad que opera sabar. </h1>
        <h1>4.2. Sabar recolecta, procesa y utiliza sus datos personales (de ahora en adelante “datos”) únicamente si ha obtenido su consentimiento o una disposición legal que permita la recolección, procesamiento o utilización de sus datos. </h1>
        <h1>4.3. Sabar recolecta, procesa o utiliza dichos datos que son necesarios para el desempeño de los servicios ofrecidos por sabar y/o para el uso y operación del sitio web/aplicaciones. </h1>
        <h1>4.4. Si el usuario ha aceptado recibir información sobre sabar durante el proceso de registro como miembro de sabar o en un momento posterior haciendo uso los servicios de sabar, el usuario recibirá información periódica sobre los productos. El consentimiento dado por el usuario puede ser revocado en cualquier momento por escrito o por correo electrónico. Esta aceptación se materializa dando clic en el enlace del correo  cuando el usuario se registra para recibir boletines de noticias.</h1>
        <br>
        
        <h3 class="text-primary">5. Obligaciones de los usuarios</h3>
        <h1>5.1. El usuario es responsable de la autenticidad de la información que proporciona en la plataforma de sabar y este debe de asegurarse de no violar ninguna ley bajo el uso de identificaciones falsas o uso de tarjetas de crédito de terceras personas o  suplantando   identidad de terceros . </h1>
        <h1>5.2. El usuario se compromete a no usar programas o software malintencionado, que genere  acceso ilícito  o información automática falsa en la plataforma de sabar.</h1>
        <h1>5.3. Si hay un incumplimiento en los términos y condiciones, THB se reserva el derecho a eliminar el o los  usuarios en cuestión, y a restringir  el acceso. El derecho a la persecución de cualquier acto delictivo que  se  pretendan ejecutar o hayan  ejecutado,  no se verá limitado en forma alguna. Luego el derecho a presentar denuncias de o por actos delictivos no se verá  afectado.</h1>
        <br>

        <h3 class="text-primary">6. Terminación</h3>
        <h1>6.1. Sabar se reserva el derecho a terminar el acceso otorgado a un usuario, y a eliminar su registro dentro de un periodo de una semana, notificando a través del correo electrónico por el uso inadecuado del sitio web. El usuario también puede a petición suya, terminar su propio acceso y registro a través de su petición a notificaciones@sabar.mx </h1>
        <br>
        
        <h3 class="text-primary">7. Responsabilidad</h3>
        <h1>7.1. Sabar no es responsable del cumplimiento de las obligaciones  y  responsabilidades de las aseguradoras. En particular, sabar se limita a solo asistir de forma electrónica al cliente ante  cualquier aclaración, reclamación o información que solicite el asegurado a la Aseguradora.</h1>
        <h1>7.2. Todos los acuerdos que surgen a través de esta plataforma son entre el usuario de sabar y la respectiva aseguradora con que se contrató el seguro. En particular sabar no actúa como aseguradora en ningún momento,  ni asume  riesgos,  ni  da  coberturas,  ni procesa los pagos en cuentas propias. Cada aseguradora tendrá sus propios términos y condiciones aplicables. El procesamiento de las cotizaciones, generación de pólizas y el pago  de primas, al igual que las preguntas para la   colocación  de la oferta  o propuesta  del riesgo  a contratar, son las correspondientes a las que la aseguradora solicita, según el seguro que se cotiza. Sabar no se involucra en acuerdos  precontractuales y contractuales con la Aseguradora, ni en  las  reclamaciones entre el usuario de sabar y la aseguradora contratada. </h1>
        <h1>7.3. Por cuestiones técnicas, las actualizaciones de precios que recibimos por parte de las aseguradoras no siempre son hechas en moneda nacional. En consecuencia, es posible que el precio desplegado en las páginas de sabar esté expresado en dólares, sin embargo, todos los cargos se harán en moneda nacional al tipo de cambio que indique la aseguradora en cuestión. Sabar no puede garantizar la exactitud de la información, especialmente en lo concerniente a precios y tipo de cambio. </h1>
        <h1>7.4. Sabar no es responsable de los errores técnicos cuya causa está fuera del ámbito de responsabilidad de sabar o por daños causados por fuerza mayor  en y  por la información  que   corresponde  a las  aseguradoras. Sabar no garantiza la disponibilidad ininterrumpida de datos y puede realizar tareas de mantenimiento durante un periodo de tiempo libremente elegido y   por  requerimiento de necesidades  técnicas.</h1>
        <br>

        <h3 class="text-primary">8. Cambios a los términos y condiciones</h3>
        <h1> Los términos y condiciones actuales aplican al acceder y usar sabar. Los usuarios registrados reciben y   recibirán  notificaciones sobre cambios, a través de correo electrónico. Los usuarios pueden descargar e imprimir los términos y condiciones actuales en su propio sistema de computadora.</h1>
        <br>

        <h3 class="text-primary">9. Ley aplicable y jurisdicción</h3>
          <h1>Aplican  sin  excepción las leyes de los Estados Unidos Mexicanos.</h1>
        <br>

        <h1>Las transacciones serán efectuadas mediante la pasarela de Openpay.</h1>

      </div>


  </div>
</template>

<script>
export default {
  data:() => ({
    gen:""
  }),
  mounted(){

    // this.$swal({
    //   footer: "Lo sentimos no logró ser procesada tu cotización",
    //   text: "CODIGO POSTAL NO ESTA EN EL LISTADO RED HOSPITALARIA!",
    //   showConfirmButton: false,
    //   timer:8000
    // })

    // this.$notify({
    //   group: "foo",
    //   type: "success",
    //   title: "Este seguro no tiene requerimientos adicionales",
    //   text: "Los requerimientos ya están cubiertos, puedes continuar con el pago",
    //   duration: 10000
    // })   
    
  }
}
</script>

<style>

.vue-notification {
  position: absolute !important;
  top: 0;
  right: 0;
  width: 10rem;
  height: 10rem;
  z-index: 9999;

  padding: 10px;
  margin: 0 5px 5px;
  
  font-size: 12px;

  color: #ffffff;
  background: #44A4FC;
  border-left: 5px solid #187FE7;
}

</style>